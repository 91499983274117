<script>
import Layout from "../../layouts/main";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';
import { api } from "@/state/services";
 
export default {
  locales: {
    pt: {
      'Participate': 'Participar',
      'Enter the amount of your new deposit': 'Digite o valor do depósito',
      'Deposit is required.': 'Valor do deposito é obrigatório',
      'New Deposit': 'Novo Depósito',
      'The minimum invest is': 'O valor mínimo para investimento é de',
      'The maximum deposit amount is': 'O valor máximo do depósito é de',
      'The value should be a multiple of': 'O valor deve ser múltiplo de'
    },
    es: {
      'Participate': 'Participar',
      'Enter the amount of your new deposit': 'Ingrese el monto de su nuevo depósito',
      'Deposit is required.': 'Se requiere un monto de depósito.',
      'New Deposit': 'Nuevo Depósito',
      'The minimum invest is': 'La inversión mínima es de',
      'The maximum deposit amount is': 'O valor máximo do depósito é de',
      'The value should be a multiple of': 'O valor deve ser múltiplo de'
    }
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      value: '0,00',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
      alert: { type: '', message: '' },
    };
  },
  directives: { money: VMoney },
  validations: {
    value: { required },
  },
  methods: {
    depositSubmit() {
      this.$v.$touch();

      if (this.value) {
        this.loading = true

        api
          .post('investments/deposit', {
            value: this.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.value = '0,00'
              this.$v.$reset()

              this.alert.type = 'alert-success'
              this.alert.message = response.data.message

              this.$router.push('/investment/order/'+response.data.order.id);
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
            }

            this.loading = false
          })
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Participate') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="depositSubmit">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <b-form-group id="value" :label="t('Enter the amount of your new deposit')" label-for="value">
                <b-form-input id="value" v-model="value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.value.$error }"></b-form-input>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{ t('Deposit is required.') }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="loading == true || this.value === 'R$ 0,00'" type="submit" variant="primary">
                  {{ t('New Deposit') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>